import {
  ref, watch, computed, onBeforeMount,
} from '@vue/composition-api'
import { useRouter, onlyWithValue } from '@core/utils/utils'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function tableConfig(moduleName, arg = {}, filterClass) {
  // Use toast
  const defaultParams = {
    per_page: 25,
    search_query: '',
    order_column: 'id',
    order_type: 'desc',
    page: 1,
  }
  const toast = useToast()
  const table = ref(null)
  const perPage = ref(arg.perPage ?? defaultParams.per_page)
  const total = ref(arg.total ?? 0)
  const currentPage = ref(arg.currentPage ?? defaultParams.page)
  const perPageOptions = arg.perPageOptions ?? [25, 50, 100, 1000]
  const searchQuery = ref(arg.searchQuery ?? defaultParams.search_query)
  const sortBy = ref(arg.sortBy ?? defaultParams.order_column)
  const tableListArray = ref([])
  const isSortDirDesc = ref(arg.isSortDirDesc ?? true)
  const isBusy = ref(false)
  const extraParams = ref(arg.data ?? {})
  const endpoint = arg.endpoint ?? `${moduleName}/list`
  const { responseDataBody } = arg
  const dataMeta = computed(() => {
    const localItemsCount = table.value ? table.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })
  const isPagination = ref(false)
  const customeRoute = useRouter()
  const filters = ref(customeRoute.route.value.query.f)
  const selectedFilters = ref([])
  const isError = ref(false)
  const refetchData = () => {
    table.value.refresh()
  }
  const refetchDataAndPagination = () => {
    currentPage.value = defaultParams.page
    table.value.refresh()
  }
  const ignoreDefaultParams = (currentParamsObject, defaultParamsObject) => {
    const newParamsObject = JSON.parse(JSON.stringify(currentParamsObject))
    Object.entries(defaultParamsObject).forEach(([itemKey, itemValue]) => {
      if (currentParamsObject[itemKey] === itemValue) {
        delete newParamsObject[itemKey]
      }
    })
    return newParamsObject
  }

  const totalQuery = ref({})

  const getBody = response => {
    const body = responseDataBody ? response.data.data[responseDataBody] : response.data.data
    let data
    let totalValue = 0
    let isPaginationValue = false
    if (body.current_page) {
      isPaginationValue = true
      data = body.data
      totalValue = body.total
    } else {
      data = body
    }
    return {
      data,
      totalValue,
      isPaginationValue,
    }
  }
  onBeforeMount(() => {
    perPage.value = customeRoute.route.value.query.per_page || arg.perPageOptions || defaultParams.per_page
    currentPage.value = customeRoute.route.value.query.page || arg.currentPage || defaultParams.page
    sortBy.value = customeRoute.route.value.query.order_column || arg.sortBy || defaultParams.order_column
    searchQuery.value = customeRoute.route.value.query.search || arg.searchQuery || defaultParams.search_query
  })

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const list = (ctx, callback) => {
    isBusy.value = true
    isError.value = false
    const { query } = customeRoute.route.value
    // eslint-disable-next-line no-shadow
    if (filterClass) {
      filters.value = {
        ...filterClass.struct(),
        ...filters.value,
      }
      const filterMapping = filterClass.mapping(filters.value)
      query.f = {
        ...query.f,
        ...filterMapping,
      }
    }
    totalQuery.value = onlyWithValue({
      ...query,
      [arg.searchKey ? arg.searchKey : 'search']: searchQuery.value,
      order_column: sortBy.value,
      order_type: (isSortDirDesc.value ? 'desc' : 'asc'),
      per_page: perPage.value,
      page: currentPage.value,
      ...extraParams.value,
    })

    store
      .dispatch(endpoint, totalQuery.value)
      .then(response => {
        customeRoute.router.replace({
          query: ignoreDefaultParams(totalQuery.value, defaultParams),
        }).catch(() => {})
        // eslint-disable-next-line prefer-const
        let { data, isPaginationValue, totalValue } = getBody(response)

        if (Array.isArray(data)) {
          tableListArray.value = data
        }

        isPagination.value = isPaginationValue
        total.value = totalValue
        if (filterClass) {
          const resFilter = {
            ...filterClass.struct(),
            ...filterClass.getResponseFilter(response),
          }
          filters.value = filterClass.changeResponseFilter(resFilter)
          selectedFilters.value = filterClass.getSelectedFilters(filters.value)
        }
        if (typeof arg.getData === 'function') {
          data = arg.getData(response)
        }
        callback(data)
        if (typeof arg.getTotal === 'function') {
          total.value = arg.getTotal(response)
        }
      })
      .catch(err => {
        callback([])
        isError.value = true
        let errorText = ''
        if (err.response.status) {
          errorText = err.response.data.message ? `${err.response.data.message}` : `Oops! ${err.response.status} Server Error Occurred`
        }
        toast({
          component: ToastificationContent,
          props: {
            // title: i18n.t(`Error fetching ${moduleName} list ${errorText}`, { module: moduleName }),
            title: i18n.t(`${errorText}`, { module: moduleName }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isBusy.value = false
      })
  }

  return {
    list,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    [`${moduleName}Table`]: table,
    isBusy,
    refetchData,
    refetchDataAndPagination,
    extraParams,
    isPagination,
    endpoint,
    getBody,
    filters,
    selectedFilters,
    tableListArray,
    isError,
    totalQuery,
  }
}
